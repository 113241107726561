<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-10" style="margin-left: 5%;">
				<div class="card">
					<div class="card-header">
						<h6>Offline Excel Export</h6>
					</div>
					<div class="card-body" style="margin-left: 14%;">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row" style="margin-left: 6%;">
								<div class="col-sm-2">
									<input type="radio" v-model="offlineRequest.type" value="type1"
										class="form-check-input" id="exampleCheck1">
									<label class="form-check-label" for="exampleCheck1">အပေါင် လက်ခံ</label>
								</div>
								<div class="col-sm-2">
									<input type="radio" v-model="offlineRequest.type" value="type2"
										class="form-check-input" id="exampleCheck2">
									<label class="form-check-label" for="exampleCheck2">အရွေး လက်ခံ</label>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-sm-11">
									<input type="file" class="form-control" @change="readFile" style="width: 80%; margin-left: 6%;">
								</div>
							</div>
							<button type="submit" class="btn btn-primary float-save">လုပ်ဆောင်မည်</button>
						</form>

						<div class="card text-white bg-danger mb-3" style="width: 74%; margin-left: 5%;" v-for="err in errList" v-if="success === false">
							<div class="card-header">Row No. {{err.sheet}}</div>
							<div class="card-body">
								<p class="card-text" v-if="err.shop">- {{err.shop}}</p>
								<p class="card-text" v-if="err.item">- {{err.item}}</p>
								<p class="card-text" v-if="err.noType">- {{err.noType}}</p>
								<p class="card-text" v-if="err.no">- {{err.no}}</p>
								<p class="card-text" v-if="err.itemType">- {{err.itemType}}</p>
								<p class="card-text" v-if="err.loan">- {{err.loan}}</p>
								<p class="card-text" v-if="err.loanMmk">- {{err.loanMmk}}</p>
								<p class="card-text" v-if="err.loanText">- {{err.loanText}}</p>
								<p class="card-text" v-if="err.name">- {{err.name}}</p>
								<p class="card-text" v-if="err.address">- {{err.address}}</p>
								<p class="card-text" v-if="err.startDate">- {{err.startDate}}</p>
								<p class="card-text" v-if="err.endDate">- {{err.endDate}}</p>
							</div>
							</div>
						</div>	
						<div v-if="success === false" v-for="err in payNotFoundList" class="alert alert-danger" role="alert" style="width: 60%; margin-left: 21%;">
								Row No. {{err}} အရွေး လက်ခံ မတွေ့ပါ။
						</div>
						<div v-if="success === true" class="alert alert-success" role="alert" style="width: 60%; margin-left: 21%;">
								Success Excel Import!
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	import moment from 'moment';
	export default {
		name: 'excelExport',
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				success: false,
				shopList: [],
				itemList: [],
				errList: [],
				payNotFoundList: [],
				offlineRequest: {
					type: "type1",
					excelFile: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.mortgageRequest.id = to.params.id
					await this.itemDetail()
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				offlineExcelImport: 'offlineExcelImport',
			}),
			readFile({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.offlineRequest.excelFile = e.target.result
				};
			},
			async onSubmit() {
				this.isLoading = true
					this.errList = []
					this.payNotFoundList = []
					this.success = false
				let option = this.offlineRequest
				await this.offlineExcelImport({
					...option
				}).then(res => {
					if (res.status == "success") {
						if(this.offlineRequest.type == "type1"){
							this.errList = res.data.data
							this.errList.length > 0 ? this.success = false : this.success = true
						}
						if(this.offlineRequest.type == "type2"){
							this.errList = res.data.data.validation
							this.payNotFoundList = res.data.data.payErr
							this.errList.length > 0 || this.payNotFoundList.length > 0 ? this.success = false : this.success = true
						}

						this.isLoading = false;
					}
				}).catch(err => this.isLoading = true)
			},

		},
		async mounted() {
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}

	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>